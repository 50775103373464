import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

import { ReviewComponent } from '../components/customer-reviews'

const OurWork = ({ location }) => (
  <Layout location={location}>
    <SEO title="WebPerfex Reviews" description="Discover what our clients have to say about WebPerfex! Check out our reviews page for customer feedback on our web design, SEO, and digital marketing services." />
    <StaticQuery
      query={graphql`
        query Reviews {
          allContentfulTestimonial(sort: { order: ASC, fields: createdAt }) {
            edges {
              node {
                id
                reviewerName
                title
                review {
                  review
                }

                companyName
                avatar {
                  fluid(maxWidth: 100) {
                    ...GatsbyContentfulFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div className=' bg-gray-50'>
          <div className="container mx-auto px-6 py-12" >
            <h1 className="text-3xl md:text-4xl px-2 font-black">WebPerfex Reviews</h1>
            <div className="flex flex-wrap ">
              {data.allContentfulTestimonial.edges.map((item, i) => (
                <div className="p-2 w-full md:w-1/2">
                  <ReviewComponent
                    {...item.node}
                    reviewer={item.node.reviewerName}
                    body={item.node.review.review}
                  />
                </div>
              ))}
            </div>
          </div>
          </div>
        )
      }}
    />
  </Layout>
)

export default OurWork
